<script>
import { get } from 'vuex-pathify'
export default {
	lang: 'layout',
	data: () => ({
		searchText: null,
		showMobileSearch: false,
	}),
	computed: {
		logo() {
			return this.$srv('SiteLogo.logo')
		},
		isHome() {
			return this.$route.path === '/'
		},
		categories() {
			return this.$srv('categories')
		},
		menuLinks() {
			return this.$srv('configurableLinks')
		},
		order: get('cart/order'),
		orderItemsQty() {
			return this.order?.items.reduce((sum, item) => sum + item.qty, 0)
		},
		user: get('shop/user'),
	},
	methods: {
		showCartDrawer() {
			this.$store.set('shop/cartDrawer', true)
		},
		showMenuMobile() {
			console.log('show menu mobile')
			this.$store.set('shop/menuMobileDrawer', true)
		},
		search() {
			let txt = this.searchText ? this.searchText.trim() : ''
			if (txt.length <= 2) return
			this.$router.push({
				name: 'shop',
				params: { filters: ['shop'] },
				query: { search: txt },
			})
		},
	},
}
</script>

<template>
	<div :class="{ 'absolute-top': isHome }" class="w100">
		<SystemBar />
		<div
			class="py-4 px-4 d-flex justify-space-between align-center w100"
			:style="{ backgroundColor: isHome ? 'transparent' : 'var(--v-darkprimary-base)' }"
			app
		>
			<router-link to="/">
				<Media :src="$srv('SiteLogo.logo')" width="150" height="50" />
			</router-link>
			<div class="d-flex align-center">
				<nav class="d-none d-md-flex">
					<div class="mr-4 d-flex align-center white--text">
						<Button text :to="{ name: 'home' }">
							<v-icon left color="secondary">mdi-home</v-icon>
							<span class="white--text">Inicio</span>
						</Button>
					</div>
					<AccountBtn class="mr-4" icon-color="secondary" text-class="white--text" />
					<div class="mr-4 d-flex align-center white--text">
						<Button text @click="showCartDrawer">
							<v-icon left color="secondary">mdi-cart</v-icon>
							<v-badge :content="orderItemsQty" :value="!!orderItemsQty" color="primary">
								<span class="white--text"> {{ 'Carrito' | lang }} </span>
							</v-badge>
						</Button>
					</div>
					<!-- <div class="mr-4 d-flex align-center white--text">
						<Button text>
							<v-icon left color="secondary">mdi-earth</v-icon>
							<span class="white--text">Idioma</span>
						</Button>
					</div> -->
				</nav>
				<Button large icon class="d-md-none" @click="showMenuMobile">
					<v-icon x-large color="secondary">mdi-menu</v-icon>
				</Button>
			</div>
		</div>
	</div>
</template>

<style lang="scss">
.absolute-top {
	position: absolute;
	top: 0px;
	left: 0;
	z-index: 10;
}
</style>
