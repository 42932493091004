var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"container",staticClass:"w100 relative pb-16 mb-4 pb-md-0 mb-md-0",style:({ height: (_vm.containerHeight + "px") })},[_c('div',{staticClass:"relative w100"},[(_vm.cropper)?_c('CropperControls',{staticClass:"cropper-controls pt-4",style:({ top: (_vm.mmToPx(_vm.config.imgTop) + _vm.mmToPx(_vm.config.imgHeight)) * _vm.scale + 'px' }),attrs:{"cropper":_vm.cropper}}):_vm._e(),(_vm.scale)?_c('div',{style:({
				width: ((_vm.mmToPx(_vm.config.imgCoverWidth)) + "px"),
				height: ((_vm.mmToPx(_vm.config.imgCoverHeight)) + "px"),
				transform: ("scale(" + _vm.scale + ")"),
				position: 'absolute',
				left: 0,
				top: 0,
				transformOrigin: '0 0',
				backgroundSize: 'cover',
				backgroundImage: ("url('" + _vm.backgroundImageUrl + "')"),
			})},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!!_vm.name),expression:"!!name"}],style:({
					fontSize: _vm.mmToPx(_vm.config.nameFontSize) + 'px',
					top: _vm.mmToPx(_vm.config.nameTop) + 'px',
					zIndex: 1000,
					width: '100%',
					position: 'absolute',
					fontFamily: 'Labor Union',
					textAlign: 'center',
					color: 'var(--v-secondary-base)',
				})},[_vm._v(" "+_vm._s(_vm.name)+" ")]),_c('div',{staticClass:"absolute",style:({
					left: _vm.mmToPx(_vm.config.imgLeft) + 'px',
					top: _vm.mmToPx(_vm.config.imgTop) + 'px',
				})},[_c('v-img',_vm._b({attrs:{"contain":"","src":_vm.coverSrc}},'v-img',_vm.imgAttrs,false))],1),_c('div',{style:({
					fontSize: _vm.mmToPx(_vm.config.inscriptionFontSize) + 'px',
					top: _vm.mmToPx(_vm.config.inscriptionTop) + 'px',
					width: '100%',
					position: 'absolute',
					fontFamily: 'Labor Union',
					textAlign: 'center',
					color: 'white',
					zIndex: 1000,
				})},[_vm._v(" "+_vm._s(_vm.inscription)+" ")])]):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }