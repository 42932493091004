<template>
	<div class="w100 relative black" style="height: 70vh">
		<div
			v-for="({ image, imageMobile, link }, i) of data"
			:key="i"
			class="image-container"
			:class="{ 'd-none': currentIndex != i }"
		>
			<transition name="slide-fade" appear>
				<SafeLink :to="link" class="w100 h100" v-if="currentIndex == i">
					<Media
						class="zoom-in"
						:src="$vuetify.breakpoint.xs ? imageMobile : image"
						width="100%"
						height="100%"
						cover
					/>
				</SafeLink>
			</transition>
		</div>
		<div class="overlay" :style="`background-color: rgba(0,0,0,${opacity})`">
			<div class="btn-prev d-none d-sm-block" v-if="data.length > 1">
				<v-btn @click="prev" fab dark text>
					<v-icon x-large>mdi-chevron-left</v-icon>
				</v-btn>
			</div>
			<div class="btn-next d-none d-sm-block justispa" v-if="data.length > 1">
				<v-btn @click="next" fab dark text>
					<v-icon x-large>mdi-chevron-right</v-icon>
				</v-btn>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data: () => ({ currentIndex: 0, interval: null }),
	computed: {
		data() {
			return this.$srv('Home_A_Header', [])
		},
		opacity() {
			const { opacity } = this.data[this.currentIndex]
			return opacity ? opacity / 100 : 0.5
		},
	},
	mounted() {
		this.interval = setInterval(() => this.move(1), 4000)
	},
	methods: {
		next() {
			this.move(1, true)
		},
		prev() {
			this.move(-1, true)
		},
		move(by, userInteraction = false) {
			let currentIndex = this.currentIndex + by
			if (currentIndex > this.data.length - 1) {
				currentIndex = 0
			}
			if (currentIndex < 0) {
				currentIndex = this.data.length - 1
			}
			if (userInteraction && this.interval) {
				clearInterval(this.interval)
				this.interval = null
			}
			this.currentIndex = currentIndex
		},
	},
	beforeDestroy() {
		if (this.interval) {
			clearInterval(this.interval)
		}
	},
}
</script>

<style lang="scss" scoped>
.relative {
	position: relative;
	z-index: 2;
}

.slide-fade-enter-active,
.slide-fade-leave-active {
	transition: all 2s;
}
.slide-fade-enter,
.slide-fade-leave-to {
	opacity: 0;
}

.image-container {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;
}
.overlay {
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
	z-index: 1;
	transition: all 2s;
	pointer-events: none;
}
.zoom-in {
	animation: zoomIn 12s forwards;
}

.btn-next,
.btn-prev {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	pointer-events: auto;
}
.btn-next {
	right: 30px;
}
.btn-prev {
	left: 30px;
}
@keyframes zoomIn {
	0% {
		transform: scale(1);
	}
	100% {
		transform: scale(1.1);
	}
}
@keyframes fromBottom {
	0% {
		transform: translateY(30px);
		opacity: 0;
	}
	100% {
		transform: translateY(0);
		opacity: 1;
	}
}
</style>
