<template>
	<div
		v-if="!closed"
		class="secondary white--text font-weight-bold px-4 font-0 py-2 d-flex justify-space-between w100"
	>
		<span>{{ text }}</span>
		<v-icon small @click="closed = true">mdi-close</v-icon>
	</div>
</template>

<script>
export default {
	data() {
		return {
			closed: false,
		}
	},
	computed: {
		text() {
			return this.$srv('SystemBar.text')
		},
	},
}
</script>

<style scoped>
.absolute-top {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 10;
}
span {
	letter-spacing: 1px;
}
</style>
