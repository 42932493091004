<script>
export default {}
</script>

<template>
	<v-app>
		<AppBar />
		<MobileMenuDrawer />
		<LoginDrawer />
		<CartDrawer />
		<v-main class="primary">
			<router-view />
		</v-main>
		<Footer />
	</v-app>
</template>
