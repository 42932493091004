<template>
	<v-chip-group active-class="primary--text" column v-model="selected">
		<v-chip
			active-class="primary"
			color="gold"
			v-for="(text, i) of items"
			:key="i"
			:value="text"
			class="w100 white--text"
			style="border-radius: 8px !important"
			large
		>
			{{ text }}
		</v-chip>
	</v-chip-group>
</template>

<script>
export default {
	props: {
		value: String,
		items: Array,
	},
	data() {
		return {
			selected: this.value,
		}
	},
	model: {
		prop: 'value',
		event: 'change',
	},
	watch: {
		value() {
			this.selected = this.value
		},
		selected() {
			this.$emit('change', this.selected)
		},
	},
}
</script>

<style scoped>
.v-chip {
	white-space: unset !important;
}
</style>
