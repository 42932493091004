<script>
export default {
	name: 'ProductCard',
	props: {
		product: Object,
		listId: String,
		listIndex: Number,
	},
	computed: {
		productRoute() {
			return {
				name: 'product',
				params: { urlName: this.product.urlName, id: this.product.id },
			}
		},
		mainVariant() {
			return this.product.variants.find((variant) => variant.main) || this.product.variants[0]
		},
		prevPrice() {
			return this.mainVariant.pvPrice.prevPrice
		},
		price() {
			return this.mainVariant.pvPrice.price
		},
		discountPct() {
			return this.mainVariant.pvPrice.discountPct
		},
		mainImage() {
			return this.product.images[0].square
		},
		secondImage() {
			return this.product.images[1]?.square
		},
		currency() {
			return this.mainVariant.pvPrice.priceConfig.currency.sign
		},
		cuotasTxt() {
			return this.$srv('CuotasText.text')
		},
	},
	methods: {
		async cardClick() {
			this.$eventer.trigger('product:click', {
				product: this.product,
				variant: this.mainVariant,
				listId: this.listId,
				index: this.listIndex,
			})
		},
	},
}
</script>

<template>
	<v-card
		hover
		flat
		tile
		@click="cardClick"
		:to="productRoute"
		class="elevation-1 product-card rounded h100"
	>
		<slot name="images" v-bind="{ mainImage, secondImage }">
			<ProductCardImage :mainImage="mainImage" :secondImage="secondImage" />
		</slot>
		<div class="pt-3 white d-flex flex-column align-center product-data-container">
			<div class="line-clamp-1 font-weight-bold product-name px-3 text-center">
				{{ product.name }}
			</div>
			<div class="line-clamp-3 font-0 px-2 text-center product-info mt-1 mb-3" style="min-height: 43px">
				{{ product.info ? product.info.description : '' }}
			</div>
			<v-spacer />
			<PriceLayout
				class="px-2 align-center"
				:prevPrice="prevPrice"
				:price="price"
				:discountPct="discountPct"
				:currency="currency"
			/>
			<div
				v-if="cuotasTxt"
				class="font-0 px-2 text-center product-info mt-1 darkblue--text d-flex justify-center align-center"
			>
				<v-icon small class="mr-1" color="secondary">mdi-credit-card-outline</v-icon>
				{{ cuotasTxt }}
			</div>
			<div class="text-center w100">
				<Button class="elevation-0 mt-3 cart-btn" tile block color="secondary" height="60">
					<v-icon class="mr-1">mdi-cart-outline</v-icon> COMPRAR
				</Button>
			</div>
		</div>
		<div class="wishlist-position">
			<ProductWishlistToggler :productId="product.id" />
		</div>
	</v-card>
</template>

<style scoped>
.product-card {
	overflow: hidden;
}
.product-data-container {
	min-height: 216px;
}
.product-info {
	line-height: 1.1;
	word-break: break-word;
}
.wishlist-position {
	position: absolute;
	top: 5px;
	right: 5px;
}
</style>
