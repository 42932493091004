<script>
const pdf = {}
export default {
	components: { pdf },
	props: {
		value: Boolean,
		src: String,
		cartLoading: Boolean,
	},
	model: {
		prop: 'value',
		event: 'update-value',
	},
	data() {
		return {
			loading: true,
			loadingColorIdx: 0,
			loadingPct: 0,
			error: false,
		}
	},
	computed: {
		loadingColors() {
			return ['yellow', 'purple', 'red', 'pink']
		},
		currLoadingColor() {
			return this.loadingColors[this.loadingColorIdx]
		},
		pct100() {
			return this.loadingPct == 100
		},
	},
	watch: {
		src(value) {
			this.loading = true
			this.error = false
			if (!value) return
			if (value === 'error') {
				this.loading = false
				this.error = true
				return
			}
			this.loading = false
		},
		value(v) {
			if (v) this.startAnimation()
		},
	},
	methods: {
		setValue(value) {
			this.$emit('update-value', value)
		},
		addToCart() {
			this.$emit('add-to-cart')
		},
		startAnimation() {
			this.loadingPct = 0
			this.animateLoadingColor()
			setTimeout(() => this.animateLoadingPct(), 200)
		},
		animateLoadingColor() {
			if (!this.loading) return
			setTimeout(() => {
				if (this.loadingColorIdx == this.loadingColors.length - 1) {
					this.loadingColorIdx = 0
				} else {
					this.loadingColorIdx += 1
				}
				this.animateLoadingColor()
			}, 500)
		},
		animateLoadingPct() {
			if (!this.loading || this.loadingPct == 100) return

			setTimeout(() => {
				this.loadingPct = this.loadingPct + 1
				this.animateLoadingPct()
			}, 200)
		},
	},
	beforeDestroy() {
		this.loading = false
	},
}
</script>

<template>
	<v-overlay :value="value" :opacity="0.9" z-index="10">
		<Button class="overlay-close" text icon color="white" large @click="setValue(false)">
			<v-icon>mdi-close</v-icon>
		</Button>
		<Container class="d-flex align-center justify-center flex-column h100 py-16">
			<template v-if="loading && !error">
				<div class="font-weight-bold font-2 pb-3 white--text text-center">
					Estamos creando tu previsualización
				</div>
				<div class="font-1 pb-8 white--text">Por favor, aguarda un instante ...</div>
				<v-progress-linear
					:value="pct100 ? 0 : loadingPct"
					:indeterminate="pct100"
					:color="currLoadingColor"
					height="10"
				/>
				<div class="pt-8 font-6 white--text font-weight-bold" v-if="!pct100">{{ loadingPct }}%</div>
				<div v-else class="pt-8 font-3 white--text">Generando imagen...</div>
			</template>

			<pdf v-if="!loading && !error" :src="src" />
			<div v-if="error" class="error--text font-weight-bold font-2">
				Ha ocurrido un error al cargar la previsualización.
			</div>
			<Button
				v-if="!loading && !error"
				color="success"
				large
				:loading="cartLoading"
				@click="addToCart"
				class="mt-8"
			>
				<v-icon class="mr-1">mdi-cart-outline</v-icon> Agregar al carrito
			</Button>
		</Container>
	</v-overlay>
</template>

<style scoped>
.v-overlay >>> .v-overlay__content {
	width: 100%;
	height: 100%;
}
.v-overlay .overlay-close {
	position: absolute;
	top: 20px;
	right: 20px;
}
</style>
