<script>
export default {
	name: 'FrequentlyAsked',
	data() {
		return {
			items: this.$srv('Home_E_FrequentlyAsked', []),
		}
	},
}
</script>

<template>
	<div class="relative">
		<v-img src="./assets/estrella.png" class="back-img" width="400" contain></v-img>
		<Container v-if="items">
			<v-row>
				<v-col cols="12">
					<div class="font-4 text-center font-weight-bold secondary--text font-alt title-font relative">
						Preguntas Frecuentes
					</div>
				</v-col>
				<v-col cols="12">
					<v-expansion-panels focusable flat dark>
						<v-expansion-panel v-for="(item, i) in items" :key="i" class="pb-3 transparent">
							<v-expansion-panel-header class="font-2 font-weight-bold secondary--text">
								{{ `${i + 1}. ${item.question}` }}
							</v-expansion-panel-header>
							<v-expansion-panel-content>
								<div class="pt-4">
									{{ item.answer }}
								</div>
							</v-expansion-panel-content>
						</v-expansion-panel>
					</v-expansion-panels>
				</v-col>
			</v-row>
		</Container>
	</div>
</template>

<style lang="scss" scoped>
::v-deep .v-expansion-panel-header {
	border-radius: 16px !important;
	border: 1px solid var(--v-secondary-base) !important;
}
::v-deep i {
	color: var(--v-secondary-base) !important;
}
.v-expansion-panel.v-expansion-panel--active {
	.v-expansion-panel-header {
		color: var(--v-primary-base) !important;
		background-color: var(--v-secondary-base) !important;
		& ::v-deep * {
			color: #fff !important;
		}
	}
}
.back-img {
	position: absolute;
	top: 0;
	right: 0;
	transform: translateX(50px);
}
</style>
