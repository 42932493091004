<script>
import { get, sync } from 'vuex-pathify'
import dafLogo from './daf-logo.png'

export default {
	name: 'MobileMenuDrawer',
	computed: {
		menuMobileDrawer: sync('shop/menuMobileDrawer'),
		user: get('shop/user'),
		links() {
			return [
				{ text: 'Inicio', url: '/' },
				{ text: 'Test2', url: '/test2' },
			]
		},
		dafLogo() {
			return dafLogo
		},
	},
	methods: {
		showLoginDrawer() {
			this.menuMobileDrawer = false
			this.$nextTick(() => {
				this.$store.set('shop/loginDrawer', true)
			})
		},
		showCartDrawer() {
			this.menuMobileDrawer = false
			this.$nextTick(() => {
				this.$store.set('shop/cartDrawer', true)
			})
		},
	},
	watch: {
		menuMobileDrawer(v) {
			console.log('CHANGED MENU MOBILE', v)
		},
	},
}
</script>

<template>
	<v-navigation-drawer
		class="pa-4 pr-0 pl-0"
		v-model="menuMobileDrawer"
		width="600"
		max-width="100%"
		overlay-color="#000"
		temporary
		style="z-index: 200"
		:overlay-opacity="0.8"
		fixed
		app
	>
		<v-list-item class="px-2 pb-3">
			<div class="pl-4">
				<router-link :to="{ name: 'home' }">
					<img
						:alt="$srv('BusinessInfo.name')"
						class="shrink mr-sm-2 mr-10 mobile-menu-drawer__logo"
						:src="dafLogo"
					/>
				</router-link>
			</div>
			<v-spacer></v-spacer>
			<v-btn icon @click="menuMobileDrawer = false">
				<v-icon x-large color="primary">mdi-close</v-icon>
			</v-btn>
		</v-list-item>
		<v-divider class="mb-4"></v-divider>
		<div>
			<v-list>
				<v-list-item to="/" link>
					<v-list-item-icon>
						<v-icon>mdi-home</v-icon>
					</v-list-item-icon>
					<v-list-item-title> Inicio </v-list-item-title>
				</v-list-item>
				<v-list-item link @click="showCartDrawer">
					<v-list-item-icon>
						<v-icon>mdi-cart</v-icon>
					</v-list-item-icon>
					<v-list-item-title> Carrito </v-list-item-title>
				</v-list-item>
				<v-list-item link>
					<v-list-item-icon>
						<v-icon>mdi-earth</v-icon>
					</v-list-item-icon>
					<v-list-item-title> Idioma </v-list-item-title>
				</v-list-item>
				<v-list-item v-if="user" :to="{ name: 'user.account' }" @click="menuMobileDrawer = false" link>
					<v-list-item-icon>
						<v-icon> mdi-account </v-icon>
					</v-list-item-icon>
					<v-list-item-title>{{ user.firstname }}</v-list-item-title>
				</v-list-item>
				<v-list-item v-else @click.stop="showLoginDrawer" link>
					<v-list-item-icon>
						<v-icon> mdi-account </v-icon>
					</v-list-item-icon>
					<v-list-item-title> Acceder </v-list-item-title>
				</v-list-item>
			</v-list>
		</div>
	</v-navigation-drawer>
</template>

<style scoped>
::v-deep i {
	color: var(--v-primary-base) !important;
}
::v-deep .v-list-item .theme--light {
	padding: 0 !important;
}
.mobile-menu-drawer__logo {
	height: 45px;
	width: auto;
}
</style>
