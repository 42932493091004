<script>
export default {
	name: 'ProductCard',
	props: {
		product: Object,
	},
	computed: {
		productRoute() {
			return {
				name: 'product',
				params: { urlName: this.product.urlName, id: this.product.id },
			}
		},
		mainVariant() {
			return this.product.variants.find((variant) => variant.main) || this.product.variants[0]
		},
		prevPrice() {
			return this.mainVariant.pvPrice.prevPrice
		},
		price() {
			return this.mainVariant.pvPrice.price
		},
		discountPct() {
			return this.mainVariant.pvPrice.discountPct
		},
		mainImage() {
			return this.product.images[0].medium
		},
		currency() {
			return this.mainVariant.pvPrice.priceConfig.currency.sign
		},
		sm() {
			return this.$vuetify.breakpoint.smAndDown
		},
	},
}
</script>

<template>
	<div class="d-flex flex-column flex-md-row secondary rounded-xl w100">
		<div class="w100 image">
			<Media
				:src="mainImage"
				:img-class="{ 'rounded-t-xl': sm, 'rounded-l-xl': !sm }"
				width="100%"
				aspect-ratio="1"
				cover
			/>
		</div>
		<div class="pa-8 d-flex flex-column justify-center">
			<h2 class="mb-4 title-font font-6 primary--text">{{ product.name }}</h2>
			<p class="font-3 mb-4 white--text">
				{{ product.info.description }}
			</p>
			<PriceLayout
				:prevPrice="prevPrice"
				:price="price"
				:discountPct="discountPct"
				:currency="currency"
				prev-price-class="prev-price-color"
				price-class="primary--text font-6"
				discount-class="primary--text text--lighten-2 font-weight-bold"
			/>
			<div class="mt-4">
				<Button shaped x-large color="primary" @click="$router.push(productRoute)">Comprar Ahora</Button>
			</div>
		</div>
	</div>
</template>

<style>
.image {
	width: 400px;
	max-width: 35%;
}
@media screen and (max-width: 960px) {
	.image {
		width: 100%;
		max-width: 100%;
	}
}
.prev-price-color {
	color: #916f43;
}
</style>
