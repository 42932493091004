<template>
	<div>
		<div v-for="(data, i) of dataArr" :key="i">
			<Media width="100%" :src="data.image" />
		</div>
	</div>
</template>

<script>
export default {
	computed: {
		dataArr() {
			return this.$srv('Home_C_Banners', [])
		},
	},
}
</script>

<style></style>
