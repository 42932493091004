<script>
import { Flicking } from '@egjs/vue-flicking'
import { Arrow, Fade } from '@egjs/flicking-plugins'
import '@egjs/flicking-plugins/dist/arrow.css'

export default {
	components: {
		Flicking,
	},
	props: {
		images: Array,
		startIndex: Number,
		value: Boolean,
	},
	model: {
		prop: 'value',
		event: 'input',
	},
	data() {
		return {
			plugins: [new Arrow(), new Fade()],
			dialog: false,
		}
	},
	watch: {
		value(val) {
			if (val) document.querySelector('html').style.overflow = 'hidden'
			else document.querySelector('html').removeAttribute('style')
			this.dialog = val
		},
		startIndex(i) {
			this.$nextTick(() => {
				this.$refs.flicking0.moveTo(i, 0)
			})
		},
	},
	created() {
		this.dialog = this.value
	},
}
</script>

<template>
	<div v-if="images">
		<v-overlay :value="dialog" :opacity="0.7">
			<v-btn class="close-btn" icon x-large dark @click="$emit('input', false)">
				<v-icon x-large>mdi-close</v-icon>
			</v-btn>
			<Flicking :plugins="plugins" class="w100 h100" ref="flicking0">
				<Media
					class="rounded"
					width="100%"
					height="100%"
					v-for="(image, i) of images"
					:key="i"
					:src="image"
				/>
				<span slot="viewport" class="flicking-arrow-prev"></span>
				<span slot="viewport" class="flicking-arrow-next"></span>
			</Flicking>
		</v-overlay>
	</div>
</template>

<style scoped>
::v-deep .v-overlay__content {
	width: 90vw !important;
	height: 90vh !important;
}
.close-btn {
	position: fixed;
	right: 15px;
	top: 15px;
	z-index: 10;
}
.flicking-arrow-disabled {
	display: none;
}
</style>
