<script>
export default {
	lang: 'home',
	data() {
		return {
			validation: null,
			isSuccess: false,
			isLoading: false,
			email: '',
		}
	},
	computed: {
		data() {
			return this.$srv('Home_D_Newsletter')
		},
		md() {
			return this.$vuetify.breakpoint.md
		},
	},
	methods: {
		saveEmail() {
			if (this.isLoading) return
			this.$shopApi.post({
				url: '/newsletter-email',
				data: {
					email: this.email,
				},
				loading: (v) => (this.isLoading = v),
				onValidation: ({ validation }) => (this.validation = validation),
				onSuccess: () => {
					this.isSuccess = true
				},
			})
		},
	},
}
</script>

<template>
	<div class="secondary" style="z-index: 10">
		<Container class="pa-0">
			<form class="py-16 relative px-16 d-flex justify-md-end justify-center" v-if="data">
				<div class="img-container">
					<Media :src="data.img" width="566" class="d-none d-md-block" />
				</div>
				<v-spacer v-if="md" />
				<div>
					<div
						class="primary--text font-md-6 font-4 font-weight-bold text-center mb-6 text-center relative w100"
					>
						<Container max-width="550" width="100%" class="relative">
							<div class="background-filter"></div>
							<span class="relative">{{ data.text }}</span>
						</Container>
					</div>
					<Container :width="'600px'" max-width="90vw" class="relative pa-0">
						<div v-if="isSuccess" class="text-center">
							<div
								class="d-inline-block white--text primary rounded-xl px-4 py-2 font-1 font-weight-bold"
							>
								{{ 'Gracias por registrar tu correo en nuestro newsletter!' | lang }}
							</div>
						</div>
						<div v-else>
							<Validator :validation="validation">
								<TextField
									v-model="email"
									:label="$lang('Ingresa tu email')"
									solo
									rounded
									height="55px"
								/>
							</Validator>
							<Button class="cta" rounded elevation="0" @click="saveEmail" :loading="isLoading">
								{{ 'Suscribirme' | lang }}
							</Button>
						</div>
					</Container>
				</div>
			</form>
		</Container>
	</div>
</template>

<style lang="scss" scoped>
.background-filter {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	max-width: 90vw;
	height: 100%;
	background-color: var(--v-secondary-base);
	filter: blur(15px);
	opacity: 0.5;
}
.img-container {
	position: absolute;
	bottom: 0;
	left: 0;
}
.cta {
	position: absolute;
	right: 0;
	top: 0;
	height: 55px !important;
}
::v-deep .v-messages__message {
	color: var(--v-primary-base);
}
</style>
