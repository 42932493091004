<template>
	<section class="relative">
		<v-img class="back-img d-none d-md-block" src="./assets/escudo.png"></v-img>

		<h1 class="py-4 relative font-8 text-center white--text mb-4 title-font secondary--text">
			{{ data.title }}
		</h1>
		<Container>
			<div v-for="product of data.products" :key="product.id" class="w100">
				<Home-ProductCard :product="product" />
			</div>
		</Container>
	</section>
</template>

<script>
export default {
	computed: {
		data() {
			return this.$srv('Home_B_Products')
		},
	},
}
</script>

<style scoped>
.back-img {
	position: absolute;
	top: 0px;
	left: 5px;
}
</style>
