<script>
export default {
	props: {
		text: Boolean,
		icon: Boolean,
		shaped: Boolean,
		color: {
			type: String,
			default: 'primary',
		},
	},
	computed: {
		fontColorClass() {
			if (this.text || this.icon || !this.color) return ''
			switch (this.color) {
				default:
					return 'white--text'
			}
		},
	},
	inheritAttrs: false,
}
</script>
<template>
	<v-btn
		v-bind="{ ...$attrs, ...$props }"
		v-on="$listeners"
		:class="{ [fontColorClass]: true, shaped }"
		:color="color"
	>
		<slot />
	</v-btn>
</template>

<style scoped>
.v-btn {
	text-transform: none;
}
.shaped {
	border-radius: 0 12px 0 12px !important;
}
</style>
