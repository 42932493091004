<script>
export default {
	lang: 'books',
	props: {
		value: Boolean,
		src: String,
		options: Object,
		filename: String,
	},
	model: {
		prop: 'value',
		event: 'input',
	},
	data() {
		return {
			cropper: null,
			isMax: false,
			cutLoading: false,
			currentScale: null,
			cropperKey: 0,
		}
	},
	computed: {
		displayWidth() {
			let margin = this.$vuetify.breakpoint.xs ? 50 : 50
			return Math.min(550, window.innerWidth) - margin
		},
		maxScale() {
			return this.displayWidth / this.options.minWidth
		},
	},
	methods: {
		async done() {
			this.cutLoading = true
			let src = await new Promise((resolve) => {
				setTimeout(async () => {
					const { canvas } = this.cropper.getResult()
					let src = canvas.toDataURL('image/png')
					// let file = srcToFile(src, this.filename)
					resolve(src)
				}, 1000)
			})

			this.toggleDialog(false)
			this.cutLoading = false
			this.$emit('done', src)
		},
		toggleDialog(v) {
			this.$emit('input', v)
		},
	},
}
</script>

<template>
	<v-dialog :value="value" fullscreen transition="dialog-bottom-transition">
		<div class="d-flex flex-column w100 h100 justify-center align-center white">
			<div class="font-4 my-4 darkblue--text text-center px-4 px-md-0">
				{{ $lang('Ubicá tu foto para que quede perfecta') }}
			</div>
			<div class="d-flex relative">
				<div
					class="cropper-controls mt-4 d-flex flex-md-column justify-center pr-md-4"
					style="gap: 10px"
					v-if="cropper"
				>
					<v-btn color="gold" class="py-2" @click="cropper.zoom(1.05)" small fab>
						<v-icon color="white">mdi-plus</v-icon>
					</v-btn>
					<v-btn color="gold" class="py-2" @click="cropper.zoom(0.95)" small fab>
						<v-icon color="white">mdi-minus</v-icon>
					</v-btn>
					<v-btn
						color="gold"
						class="py-2"
						@click="cropper.rotate(90)"
						small
						fab
						:disabled="!options.canRotate"
					>
						<v-icon color="white">mdi-rotate-right</v-icon>
					</v-btn>
					<v-btn color="gold" class="py-2" @click="cropper.flip(true, false)" small fab>
						<v-icon color="white">mdi-flip-horizontal</v-icon>
					</v-btn>
				</div>
				<BookCoverInput-Image-Cropper
					@cropper-ready="cropper = $event"
					:value="src"
					:options="options"
					:display-width="displayWidth"
				/>
			</div>
			<div class="py-4 d-flex align-center justify-center pt-md-0 pt-16 mt-8">
				<Button @click="toggleDialog(false)" text class="mr-4"> Cancelar </Button>
				<Button @click="done" rounded color="primary" x-large :disabled="!cropper" :loading="cutLoading">
					{{ $lang('Listo!') }}
				</Button>
			</div>
		</div>
	</v-dialog>
</template>

<style scoped lang="scss">
.dialog-close-btn {
	position: fixed;
	right: 15px;
	top: 15px;
}

.cropper-controls {
	position: absolute;
	left: 0;
	top: 50%;
	transform: translate(-100%, -50%);
	@media screen and (max-width: 960px) {
		bottom: 0;
		left: 50%;
		transform: translate(-50%, calc(100% + 10px));
	}
}
</style>
