<script>
import CustomizationFields from './comp_CustomizationFields.vue'

export default {
	extends: CustomizationFields,
	props: {
		submitLoading: Boolean,
	},
	created() {
		this.$assignDeep(this.customization.data, {
			name: null,
			nameb: null,
			gender: null,
		})
	},
	computed: {
		receiverInputLabel() {
			return this.config.receiverInputLabel || 'Nombre del nene/a'
		},
		giverInputLabel() {
			return this.config.giverInputLabel || 'Nombre de quién regala'
		},
		xs() {
			return this.$vuetify.breakpoint.xs
		},
		sm() {
			return this.$vuetify.breakpoint.sm
		},
	},
}
</script>

<template>
	<div class="d-flex align-center justify-center flex-column flex-sm-row flex-sm-wrap flex-md-nowrap px-6">
		<div class="white--text text-center text-md-left pr-0 pr-md-8 pb-4 pb-md-0" :class="{ w100: xs || sm }">
			<div class="font-3 font-md-1 nowrap" style="line-height: 1.4">
				Ingresa estos datos y<br />previsualiza tu libro
			</div>
		</div>
		<div
			class="pr-sm-8 pb-6 pb-sm-0 py-4 py-sm-0 flex-shrink-0 flex-grow-1"
			:class="{ w100: xs }"
			style="min-width: 234px"
		>
			<TextField
				v-model="customization.data.name"
				label="Nombre – para quién es"
				class="customization-tf"
				dense
				plain
				solo
				height="50"
			/>
		</div>
		<div
			class="pr-sm-8 pb-6 pb-sm-0 py-4 py-sm-0 flex-shrink-0 flex-grow-1"
			:class="{ w100: xs }"
			style="min-width: 234px"
		>
			<TextField
				v-model="customization.data.name2"
				label="Nombre – de parte de quién"
				class="customization-tf"
				dense
				plain
				solo
				height="50"
			/>
		</div>
		<div class="pr-md-8 py-4 py-sm-0" style="width: 205px" :class="{ w100: xs }">
			<Select
				label="Idioma"
				:items="config.langs"
				v-model="customization.data.lang"
				class="customization-tf"
				dense
				plain
				solo
				height="50"
			/>
		</div>
		<div class="w100" v-if="sm"></div>
		<div class="py-6 py-md-0 ml-sm-8 ml-md-0">
			<Button
				color="primary"
				dark
				rounded
				class="px-16 px-md-8 px-lg-16 nowrap font-weight-bold"
				x-large
				:loading="submitLoading"
				@click="$emit('submit', customization.data)"
			>
				Crear tu libro
			</Button>
		</div>
	</div>
</template>
