var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Container',[_c('div',{ref:"drubbitorParent",staticClass:"drubbitor white"},[(_vm.drubbitor)?_c('div',{staticClass:"drubbitor__content"},_vm._l((_vm.drubbitor.drubtree),function(container,containerIndex){return _c('div',{key:container.id,staticClass:"drubbitor__content__container"},[_c('div',{staticClass:"drubbitor__menu"},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"dark":"","fab":"","x-small":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-dots-vertical ")])],1)]}}],null,true)},[_c('v-list',_vm._l((_vm.containerMenu),function(item,index){return _c('v-list-item',{key:index,attrs:{"link":"","dense":""},on:{"click":function($event){return item.action(containerIndex)}}},[_c('v-list-item-title',[_vm._v(_vm._s(item.title))])],1)}),1)],1)],1),_vm._l((container.children),function(row,rowIndex){return _c('div',{key:rowIndex,staticClass:"d_row d-flex flex-nowrap",on:{"mouseover":function($event){_vm.currentRow = row}}},[_c('div',{staticClass:"d_row__menu pl-2",class:{ hiddificar: row != _vm.currentRow }},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"dark":"","fab":"","x-small":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-dots-vertical ")])],1)]}}],null,true)},[_c('v-list',_vm._l((_vm.rowMenu),function(item,index){return _c('v-list-item',{key:index,attrs:{"link":"","dense":""},on:{"click":function($event){return item.action(container, index)}}},[_c('v-list-item-title',[_vm._v(_vm._s(item.title))])],1)}),1)],1)],1),_vm._l((row.children),function(col,colIndex){return _c('div',{key:colIndex,staticClass:"d_col",style:({ width: col.width + col.widthMeasure }),on:{"mouseover":function($event){_vm.currentCol = col}}},[_c('div',{staticClass:"d-flex flex-column h100"},[_c('div',{staticClass:"pa-2 d-flex align-center justify-space-between gray lighten-3 h100"},[_c('div'),(!col.content)?_c('div',_vm._l((_vm.contentTypes),function(contentType){return _c('v-btn',{key:contentType.key,staticClass:"mr-2",attrs:{"dark":"","small":""},on:{"click":function($event){contentType.dialog = true}}},[_vm._v(" "+_vm._s(contentType.name)+" ")])}),1):_vm._e(),_c('div',{class:{ hiddificar: _vm.currentCol !== col }},[(col.contentTypeKey)?_c('v-btn',{staticClass:"mr-2",attrs:{"dark":"","fab":"","x-small":""},on:{"click":function($event){_vm.getContentType(col.contentTypeKey).dialog = true}}},[_c('v-icon',[_vm._v(" mdi-pencil ")])],1):_vm._e(),_c('v-menu',{attrs:{"offset-y":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"dark":"","fab":"","x-small":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-dots-vertical ")])],1)]}}],null,true)},[_c('v-list',_vm._l((_vm.colMenu),function(item,index){return _c('v-list-item',{key:index,attrs:{"link":"","dense":""},on:{"click":function($event){return item.action(row, colIndex)}}},[_c('v-list-item-title',[_vm._v(_vm._s(item.title))])],1)}),1)],1)],1)]),_c('div',{domProps:{"innerHTML":_vm._s(col.content)}})]),(colIndex !== row.children.length - 1)?_c('div',{staticClass:"expander",on:{"mousedown":function($event){return _vm.resizeColumn(row, $event, col)}}}):_vm._e()])})],2)})],2)}),0):_vm._e()]),_vm._l((_vm.contentTypes),function(contentType){return _c('v-dialog',{key:contentType.key,attrs:{"width":"500"},model:{value:(contentType.dialog),callback:function ($$v) {_vm.$set(contentType, "dialog", $$v)},expression:"contentType.dialog"}},[_c('v-card',[(contentType.dialog)?_c(("DrubbitorContentType-" + (contentType.componentName)),{ref:contentType.key,refInFor:true,tag:"component",attrs:{"content":_vm.currentCol.content}}):_vm._e(),_c('v-card-actions',[_c('v-btn',{on:{"click":function($event){return _vm.saveChanges(contentType.key)}}},[_vm._v("Guardar")])],1)],1)],1)})],2)}
var staticRenderFns = []

export { render, staticRenderFns }