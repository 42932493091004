<script>
import fiscalImage from './asset_fiscal.png'

export default {
	name: 'Footer',
	data() {
		return {
			items: [
				{ name: 'HOME', to: { name: 'home' } },
				{ name: 'TIENDA', to: { name: 'shop', params: { filters: ['shop'] } } },
				{ name: 'TÉRMINOS Y CONDICIONES ', to: { name: 'others.terms' } },
			],
			fiscalImage,
			logo: this.$srv('SiteLogo.logoWhite') || this.$srv('SiteLogo.logo'),
			businessInfo: this.$srv('BusinessInfo', {}),
		}
	},
}
</script>

<template>
	<v-footer padless class="darkprimary white--text text-center pt-14 pb-2">
		<Container class="pa-0">
			<v-row class="footer-content">
				<v-col cols="12" sm="4" class="d-flex flex-column d-sm-block align-center align-sm-start">
					<div class="d-flex justify-start">
						<Media :src="logo" max-height="88px" contain class="mb-3 mb-sm-6" />
					</div>
					<a v-if="businessInfo.afipLink" :href="businessInfo.afipLink" target="_blank">
						<v-img :src="fiscalImage" max-width="60" contain />
					</a>
				</v-col>
				<v-col cols="12" sm="4" class="text-center text-sm-left">
					<div class="pb-2 pb-sm-8 font-weight-bold secondary--text">TIENDA</div>
					<div v-for="item in items" :key="item.name" class="pa-1 pl-0 text-subtitle-2">
						<router-link class="text-decoration-none white--text" :to="item.to">
							{{ item.name }}
						</router-link>
					</div>
				</v-col>
				<v-col cols="12" sm="4" class="text-center text-sm-left lightgrey--text">
					<div class="pb-2 pb-sm-8 font-weight-bold secondary--text">CONTACTANOS</div>
					<a v-if="businessInfo.whatsapp" class="link-item">
						<v-icon color="secondary">mdi-whatsapp</v-icon> {{ businessInfo.whatsapp }}
					</a>
					<a v-if="businessInfo.phone" class="link-item" :href="`tel:${businessInfo.phone}`">
						<v-icon color="secondary">mdi-phone</v-icon> {{ businessInfo.phone }}
					</a>
					<a v-if="businessInfo.email" class="link-item" :href="`mailto:${businessInfo.email}`">
						<v-icon color="secondary">mdi-email-multiple-outline</v-icon> {{ businessInfo.email }}
					</a>
					<div class="d-flex mt-5 justify-center justify-sm-start">
						<div>
							<a
								v-if="businessInfo.facebook"
								class="link-item"
								:href="businessInfo.facebook"
								target="_blank"
							>
								<Button fab small class="yellow">
									<v-icon class="mr-0" color="secondary">mdi-facebook</v-icon>
								</Button>
							</a>
						</div>
						<div class="ml-2">
							<a
								v-if="businessInfo.instagram"
								class="link-item"
								:href="businessInfo.instagram"
								target="_blank"
							>
								<Button fab small class="yellow">
									<v-icon class="mr-0" color="secondary">mdi-instagram</v-icon>
								</Button>
							</a>
						</div>
					</div>
				</v-col>
				<v-col cols="12" class="text-center text-lg-left pt-14 text-caption">
					© {{ new Date().getFullYear() }} DAF Impresiones. Todos los derechos reservados.
				</v-col>
			</v-row>
		</Container>
	</v-footer>
</template>

<style scoped>
.link-item {
	padding: 6px 0;
	color: white !important;
	white-space: nowrap;
}
a.link-item {
	display: block;
	text-decoration: none;
}
.link-item >>> .v-icon {
	margin-right: 4px;
	color: white;
}

.v-footer {
	overflow: hidden;
}
.footer-content {
	z-index: 1;
	position: relative;
}
</style>
