<script>
import { Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css'

export default {
	name: 'CropperForBook',
	props: ['value', 'options', 'displayWidth'],
	components: {
		Cropper,
	},
	data() {
		return {
			aspectRatio: this.options.width / this.options.height,
			loading: true,
			src: '',
		}
	},
	computed: {
		cSize() {
			let w = this.displayWidth
			let h = (this.options.height * w) / this.options.width
			return { w, h }
		},
		cropperStyles() {
			return {
				height: this.cSize.h + 'px',
				width: this.cSize.w + 'px',
				minHeight: this.cSize.h + 'px',
				minWidth: this.cSize.w + 'px',
			}
		},
		maxWidth() {
			return this.mmToPx(this.options.width) * 3
		},
	},
	methods: {
		cropperReady() {
			this.loading = false
			this.$emit('cropper-ready', this.$refs.cropper)
		},
		init() {
			if (!this.value) return
			if (typeof this.value === 'string') {
				this.src = this.value
				return
			}
			const reader = new FileReader()
			reader.onload = (e) => {
				this.src = e.target.result
			}
			console.log({ value: this.value })
			reader.readAsDataURL(this.value)
		},
		sizeRestrictionsAlgorithm() {
			return {
				minWidth: this.options.width,
				minHeight: this.options.height,
			}
		},
		mmToPx(mm, dpi = 300) {
			return Number(((dpi / 25.4) * mm).toFixed(2))
		},
		change(x) {
			console.log(x)
		},
	},
	watch: {
		value() {
			this.init()
		},
	},
	created() {
		if (this.value) this.init()
		console.log(this.options)
	},
}
function canvasToFile(canvas) {
	return new Promise((resolve) => {
		canvas.toBlob((blob) => {
			const file = new File([blob], 'cropped.png', {
				type: 'image/png',
			})
			resolve(file)
		})
	})
}
</script>

<template>
	<div class="d-flex align-center relative cropper-border-dash" v-if="src" v-show="!loading">
		<cropper
			background-class="cropperBackground"
			:style="cropperStyles"
			@ready="cropperReady"
			@change="change"
			:src="src"
			:stencil-props="{
				handlers: {},
				movable: false,
				scalable: false,
				aspectRatio,
			}"
			:resize-image="{
				adjustStencil: false,
			}"
			:stencil-size="{
				width: cSize.w,
				height: cSize.h,
			}"
			:sizeRestrictionsAlgorithm="sizeRestrictionsAlgorithm"
			:canvas="{
				width: mmToPx(options.width),
				height: mmToPx(options.height),
				maxWidth,
			}"
			image-restriction="stencil"
			ref="cropper"
			class="cropper"
		/>
	</div>
</template>

<style>
.cropper {
	background: #ddd;
	cursor: move;
}
.no-cropper {
	border: 1px dashed black;
	background-color: rgba(255, 255, 255, 0.2);
}
.cropperBackground {
	background: none;
}
.cropper-border-dash {
	border: 2px dashed var(--v-green-base);
}
</style>
