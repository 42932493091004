class Column {
	constructor(parentWidth) {
		this.parentWidth = parentWidth
		this.type = 'column'
		this.width = null
		this.widthMeasure = ''
		this.autoWidth = true
		this.onwidthchange = null
	}

	setWidth(width, widthMeasure) {
		this.autoWidth = false
		this.width = width
		this.widthMeasure = widthMeasure
		if (this.onwidthchange) this.onwidthchange(this)
	}

	getWidthInPer() {
		if (this.widthMeasure === '%') return this.width
		return (this.width * 100) / this.parentWidth
	}
}

class Row {
	constructor(width) {
		this.type = 'row'
		this.width = width
		const defaultCol = new Column(width)
		defaultCol.onwidthchange = () => {
			this.renderColumns()
		}
		this.children = [defaultCol]
		this.renderColumns()
	}

	renderColumns() {
		const reserverdPer = this.children.reduce((acc, curr) => {
			if (!curr.autoWidth) acc += curr.getWidthInPer()
			return acc
		}, 0)
		const freePer = 100 - reserverdPer
		const autoWidthCols = this.children.filter((x) => x.autoWidth)
		const widthForAutoCols = freePer / autoWidthCols.length

		this.children.forEach((col) => {
			if (col.autoWidth) {
				col.width = widthForAutoCols
				col.widthMeasure = '%'
			}
		})
	}

	insertColBeforeTo(index, col = new Column(this.width)) {
		col.onwidthchange = () => {
			this.renderColumns()
		}
		this.children.splice(index, 0, col)
		this.renderColumns()
	}
}

class Container {
	constructor(width) {
		if (!width) {
			throw new Error('Width is required')
		}
		this.width = width
		this.children = [new Row(width)]
		this.type = 'container'
	}

	insertRowBeforeTo(i, row = new Row(this.width)) {
		this.children.splice(i, 0, row)
	}
}

class Drubbitor {
	constructor({ width }) {
		this.width = width
		this.drubtree = [new Container(width)]
	}

	insertContainerBeforeTo(i, container = new Container(this.width)) {
		this.drubtree.splice(i, 0, container)
	}

	removeContainer(i) {
		this.drubtree.splice(i, 1)
	}
}

export { Drubbitor }
