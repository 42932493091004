<template>
	<div contenteditable="true" ref="content"></div>
</template>

<script>
export default {
	props: {
		content: String,
	},
	methods: {
		getResult() {
			return this.$refs.content.innerHTML
		},
	},
	mounted() {
		if (this.content) this.$refs.content.innerHTML = this.content
	},
}
</script>

<style></style>
