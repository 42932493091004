<script>
import { breakpointValue } from '@/__shared/utils/lib_utils'

export default {
	name: 'Container',
	props: {
		fluid: [Boolean, Object],
		width: [String, Number, Object],
		maxWidth: [String, Number, Object],
	},
	computed: {
		cFluid() {
			return breakpointValue(this.$vuetify.breakpoint, this.fluid)
		},
		cWidth() {
			if (this.cFluid) return
			let w = this.width && breakpointValue(this.$vuetify.breakpoint, this.width)
			if (!w) return
			return isNaN(w) ? w : `${w}px`
		},
		cMaxWidth() {
			if (this.cFluid) return
			let mw = this.maxWidth && breakpointValue(this.$vuetify.breakpoint, this.maxWidth)
			if (!mw) return
			return isNaN(mw) ? mw : `${mw}px`
		},
	},
}
</script>

<template>
	<div
		class="container"
		:class="{ 'container--fluid': cFluid }"
		:style="{ width: cWidth, 'max-width': cMaxWidth }"
	>
		<slot></slot>
	</div>
</template>

<style lang="scss">
.container {
	width: 1440px;
	max-width: 90%;
	padding: 12px;
	&.container--fluid {
		width: 100%;
		max-width: 100%;
		padding: 12px 0;
		& > .row {
			margin-left: 0;
			margin-right: 0;
			& > .col-12 {
				padding-left: 0;
				padding-right: 0;
			}
		}
	}
}
</style>
