<script>
export default {
	lang: 'book',
	props: {
		validation: {
			type: Object,
			required: true,
		},
		customizationReference: {
			type: Object,
			required: true,
		},
		config: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			customization: this.customizationReference,
			coverFile: null,
			lang: this.config.langs[0].value,
		}
	},
	watch: {
		coverFile(newValue) {
			this.$emit('change:file', newValue)
		},
		lang() {
			this.setLang()
			this.setDefaultInscription()
		},
	},
	methods: {
		setDefaultInscription() {
			this.$assign(this.customization.data, {
				inscription: this.config.inscriptions[0][`inscription_${this.lang}`],
			})
		},
		setLang() {
			this.$assign(this.customization.data, { lang: this.lang })
		},
	},
	computed: {
		inscriptions() {
			return this.config.inscriptions.map((item) => {
				return item[`inscription_${this.lang}`]
			})
		},
	},
	created() {
		this.setLang()
		this.setDefaultInscription()
	},
}
</script>

<template>
	<Validator :validation="validation">
		<v-row>
			<v-col md="6" cols="12">
				<TextField
					v-model="customization.data.name"
					validator-key="customization.data.name"
					:label="$lang('Escribí tu nombre o mensaje ')"
					:counter="config.nameMaxLen"
					outlined
				/>
				<div class="mb-2">
					<div class="font-weight-bold"><v-icon class="mb-1">mdi-camera</v-icon> Carga tu foto</div>
					<div>Recorda que la foto tiene que ser de buena calidad</div>
				</div>
				<div style="height: auto">
					<BookCoverInput-Image
						v-model="coverFile"
						label="Tu imagen"
						validator-key="customization.data.imgCover"
						class="mb-4"
						solo
						:options="{
							width: config.imgWidth,
							height: config.imgHeight,
						}"
					/>
				</div>
				<ValidationMessage validator-key="customization.data.img" />
			</v-col>
			<v-col md="6" cols="12">
				<div class="mb-2">
					<Select
						label="Elegi el idioma para tu mensaje"
						validator-key="customization.data.lang"
						:items="config.langs"
						outlined
						v-model="lang"
						:counter="12"
					/>
					<div>
						<div class="font-weight-bold"><v-icon class="mb-1">mdi-book-edit</v-icon> Dedicatoria</div>
						<div>Selecciona una dedicatoria para tu libro.</div>
					</div>
					<InputDedications v-model="customization.data.inscription" :items="inscriptions" />
					<ValidationMessage validator-key="customization.data.inscription" />
				</div>
			</v-col>
			<v-col cols="12" class="d-flex justify-center">
				<Button
					color="primary"
					class="px-8"
					@click="$vuetify.goTo('.preview', { offset: 30 })"
					x-large
					rounded
				>
					Listo!
					<v-icon right>mdi-arrow-down-thick</v-icon>
				</Button>
			</v-col>
		</v-row>
	</Validator>
</template>
