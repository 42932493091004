import { render, staticRenderFns } from "./comp_Home-ProductCard.vue?vue&type=template&id=8f3f4628&"
import script from "./comp_Home-ProductCard.vue?vue&type=script&lang=js&"
export * from "./comp_Home-ProductCard.vue?vue&type=script&lang=js&"
import style0 from "./comp_Home-ProductCard.vue?vue&type=style&index=0&id=8f3f4628&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports