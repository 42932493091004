<script>
export default {
	data() {
		return {
			startIndex: 0,
			overlayGallery: false,
		}
	},
	computed: {
		data() {
			return this.$srv('Home_F_Gallery')
		},
		images() {
			const { data } = this
			return [data.image_xl, data.image_m, data.image_s1, data.image_l, data.image_s2]
		},
	},
	methods: {
		showOverlayGallery(index) {
			this.startIndex = index
			this.overlayGallery = true
		},
	},
}
</script>

<template>
	<div class="relative">
		<v-img src="./assets/estrellas.png" class="back-img"></v-img>
		<Container v-if="data" class="gallery">
			<OverlayGallery :start-index="startIndex" :images="images" v-model="overlayGallery" />
			<section>
				<h1 class="mb-6 font-4 secondary--text title-font text-center relative">{{ data.title }}</h1>
				<v-row>
					<v-col cols="12" md="8">
						<Media
							cover
							width="100%"
							height="360px"
							:src="data.image_xl"
							class="elevation-2"
							@click="showOverlayGallery(0)"
						>
							<div class="image_title" v-if="data.image_xl_title">
								{{ data.image_xl_title }}
							</div>
						</Media>
					</v-col>
					<v-col cols="12" md="4">
						<Media
							cover
							width="100%"
							height="360px"
							:src="data.image_m"
							class="elevation-2"
							@click="showOverlayGallery(1)"
						>
							<div class="image_title" v-if="data.image_m_title">
								{{ data.image_m_title }}
							</div>
						</Media>
					</v-col>
					<v-col cols="12" md="3">
						<Media
							cover
							width="100%"
							height="360px"
							:src="data.image_s1"
							class="elevation-2"
							@click="showOverlayGallery(2)"
						>
							<div class="image_title" v-if="data.image_s1_title">
								{{ data.image_s1_title }}
							</div>
						</Media>
					</v-col>
					<v-col cols="12" md="6">
						<Media
							cover
							width="100%"
							height="360px"
							:src="data.image_l"
							class="elevation-2"
							@click="showOverlayGallery(3)"
						>
							<div class="image_title" v-if="data.image_l_title">
								{{ data.image_l_title }}
							</div>
						</Media>
					</v-col>
					<v-col cols="12" md="3">
						<Media
							cover
							width="100%"
							height="360px"
							:src="data.image_s2"
							class="elevation-2"
							@click="showOverlayGallery(4)"
						>
							<div class="image_title" v-if="data.image_s2_title">
								{{ data.image_s2_title }}
							</div>
						</Media>
					</v-col>
				</v-row>
			</section>
		</Container>
	</div>
</template>

<style scoped>
.back-img {
	position: absolute;
	top: -38px;
	left: 0;
	transform: translateX(190px);
	transform: rotate(130deg);
}
.gallery .v-image {
	border-radius: 10px !important;
	cursor: pointer;
}
.gallery ::v-deep .v-image__image {
	transition: transform 0.6s ease;
	cursor: pointer;
}
.gallery .v-image:hover ::v-deep .v-image__image {
	transform: scale(1.08);
}
h1 {
	color: #656565;
}
.image_title {
	position: absolute;
	top: 0;
	left: 50%;
	transform: translateX(-50%);
	background-color: var(--v-primary-base);
	color: white;
	text-transform: uppercase;
	font-size: 16px;
	height: 44px;
	display: flex;
	justify-content: center;
	align-items: center;
	min-width: 248px;
	width: auto;
	border-radius: 0px 0px 10px 10px;
}
</style>
