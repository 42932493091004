<script>
export default {
	pageView: true,
	mounted() {
		this.$prerenderReady()
	},
}
</script>

<template>
	<div>
		<Home-A-Header />
		<Home-B-Products class="my-16" />
		<Home-C-Banners />
		<Home-E-FrequentlyAsked class="my-16 py-0 py-md-16" />
		<Home-D-Newsletter />
		<Home-F-Gallery class="my-16" />
		<CustomizationPreview />
	</div>
</template>
