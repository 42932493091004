<template>
	<Container>
		<div class="drubbitor white" ref="drubbitorParent">
			<div class="drubbitor__content" v-if="drubbitor">
				<div
					v-for="(container, containerIndex) of drubbitor.drubtree"
					:key="container.id"
					class="drubbitor__content__container"
				>
					<div class="drubbitor__menu">
						<v-menu offset-y>
							<template v-slot:activator="{ on, attrs }">
								<v-btn dark v-bind="attrs" v-on="on" fab x-small>
									<v-icon small> mdi-dots-vertical </v-icon>
								</v-btn>
							</template>
							<v-list>
								<v-list-item
									v-for="(item, index) in containerMenu"
									:key="index"
									link
									dense
									@click="item.action(containerIndex)"
								>
									<v-list-item-title>{{ item.title }}</v-list-item-title>
								</v-list-item>
							</v-list>
						</v-menu>
					</div>
					<div
						v-for="(row, rowIndex) of container.children"
						:key="rowIndex"
						class="d_row d-flex flex-nowrap"
						@mouseover="currentRow = row"
					>
						<div class="d_row__menu pl-2" :class="{ hiddificar: row != currentRow }">
							<v-menu offset-y>
								<template v-slot:activator="{ on, attrs }">
									<v-btn dark v-bind="attrs" v-on="on" fab x-small>
										<v-icon small> mdi-dots-vertical </v-icon>
									</v-btn>
								</template>
								<v-list>
									<v-list-item
										v-for="(item, index) in rowMenu"
										:key="index"
										link
										dense
										@click="item.action(container, index)"
									>
										<v-list-item-title>{{ item.title }}</v-list-item-title>
									</v-list-item>
								</v-list>
							</v-menu>
						</div>
						<div
							v-for="(col, colIndex) of row.children"
							:key="colIndex"
							class="d_col"
							:style="{ width: col.width + col.widthMeasure }"
							@mouseover="currentCol = col"
						>
							<div class="d-flex flex-column h100">
								<div class="pa-2 d-flex align-center justify-space-between gray lighten-3 h100">
									<div></div>
									<div v-if="!col.content">
										<v-btn
											dark
											v-for="contentType of contentTypes"
											:key="contentType.key"
											@click="contentType.dialog = true"
											class="mr-2"
											small
										>
											{{ contentType.name }}
										</v-btn>
									</div>
									<div :class="{ hiddificar: currentCol !== col }">
										<v-btn
											dark
											fab
											x-small
											class="mr-2"
											@click="getContentType(col.contentTypeKey).dialog = true"
											v-if="col.contentTypeKey"
										>
											<v-icon> mdi-pencil </v-icon>
										</v-btn>
										<v-menu offset-y left>
											<template v-slot:activator="{ on, attrs }">
												<v-btn dark v-bind="attrs" v-on="on" fab x-small>
													<v-icon small> mdi-dots-vertical </v-icon>
												</v-btn>
											</template>
											<v-list>
												<v-list-item
													v-for="(item, index) in colMenu"
													:key="index"
													link
													dense
													@click="item.action(row, colIndex)"
												>
													<v-list-item-title>{{ item.title }}</v-list-item-title>
												</v-list-item>
											</v-list>
										</v-menu>
									</div>
								</div>
								<div v-html="col.content"></div>
							</div>
							<div
								class="expander"
								v-if="colIndex !== row.children.length - 1"
								@mousedown="resizeColumn(row, $event, col)"
							></div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<v-dialog
			v-for="contentType of contentTypes"
			v-model="contentType.dialog"
			:key="contentType.key"
			width="500"
		>
			<v-card>
				<component
					:is="`DrubbitorContentType-${contentType.componentName}`"
					:ref="contentType.key"
					:content="currentCol.content"
					v-if="contentType.dialog"
				></component>
				<v-card-actions>
					<v-btn @click="saveChanges(contentType.key)">Guardar</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</Container>
</template>

<script>
import { Drubbitor } from './unused_Drubbitor.js'
export default {
	data() {
		return {
			containerMenu: [
				{ title: 'Diseño', action: '' },
				{ title: 'Agregar arriba', action: this.addContainerUp },
				{ title: 'Agregar abajo', action: this.addContainerDown },
				{ title: 'Borrar', action: this.removeContainer },
			],
			colMenu: [
				{ title: 'Diseño', action: '' },
				{ title: 'Agregar derecha', action: (row, i) => row.insertColBeforeTo(i + 1) },
				{ title: 'Agregar izquierda', action: (row, i) => row.insertContainerBeforeTo(i) },
			],
			rowMenu: [
				{ title: 'Diseño', action: '' },
				{ title: 'Agregar arriba', action: (container, i) => container.insertRowBeforeTo(i + 1) },
				{ title: 'Agregar abajo', action: (container, i) => container.insertRowBeforeTo(i) },
			],
			contentTypes: [
				{ name: 'Texto', key: 'richText', componentName: 'RichText', dialog: false },
				{ name: 'Imagen', key: 'image', componentName: 'Imagen', dialog: false },
			],
			currentCol: null,
			currentRow: null,
			drubbitor: null,
		}
	},
	methods: {
		saveChanges(key) {
			this.getContentType(key).dialog = false
			const result = this.$refs[key][0].getResult()
			this.currentCol.content = result
			this.currentCol.contentTypeKey = key
		},
		addContainerUp(i) {
			this.drubbitor.insertContainerBeforeTo(i)
		},
		addContainerDown(i) {
			this.drubbitor.insertContainerBeforeTo(i + 1)
		},
		removeContainer(i) {
			this.drubbitor.removeContainer(i)
		},
		getContentType(key) {
			return this.contentTypes.find((x) => x.key === key)
		},
		resizeColumn(row, evt, col) {
			const $col = evt.target.parentElement
			const { width: parentWidth } = $col.parentElement.getBoundingClientRect()
			const { width: widthInPx } = $col.getBoundingClientRect()
			const { clientX: initClientX } = evt

			document.onmousemove = ({ clientX }) => {
				const movement = widthInPx + (clientX - initClientX)
				const width = col.widthMeasure === 'px' ? movement : (movement * 100) / parentWidth
				col.setWidth(width, col.widthMeasure)
			}
		},
	},
	mounted() {
		document.onmouseup = () => {
			document.onmousemove = null
		}

		const { width } = this.$refs.drubbitorParent.getBoundingClientRect()
		this.drubbitor = new Drubbitor({ width })
	},
}
</script>

<style scoped lang="scss">
.drubbitor {
	position: relative;
	&__menu {
		position: absolute;
		left: 0;
		top: 0;
		transform: translateX(-100%);
		padding-right: 16px;
	}
	&__content {
		&__container {
			position: relative;
			.d_row {
				border-bottom: 3px dashed #313131;
				position: relative;
				&__menu {
					position: absolute;
					right: 0;
					top: 5px;
					transform: translateX(100%);
				}
				.d_col {
					position: relative;
					&:last-child {
						border-right: none;
					}
					.expander {
						position: absolute;
						right: 0;
						top: 0;
						width: 0px;
						border-right: 3px dashed #313131;
						height: 100%;
						cursor: pointer;
					}
				}
			}
			&:last-child {
				.d_row:last-child {
					border-bottom: none;
				}
			}
		}
	}
}
.hiddificar {
	opacity: 0;
}
</style>
