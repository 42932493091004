var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.src)?_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loading),expression:"!loading"}],staticClass:"d-flex align-center relative cropper-border-dash"},[_c('cropper',{ref:"cropper",staticClass:"cropper",style:(_vm.cropperStyles),attrs:{"background-class":"cropperBackground","src":_vm.src,"stencil-props":{
			handlers: {},
			movable: false,
			scalable: false,
			aspectRatio: _vm.aspectRatio,
		},"resize-image":{
			adjustStencil: false,
		},"stencil-size":{
			width: _vm.cSize.w,
			height: _vm.cSize.h,
		},"sizeRestrictionsAlgorithm":_vm.sizeRestrictionsAlgorithm,"canvas":{
			width: _vm.mmToPx(_vm.options.width),
			height: _vm.mmToPx(_vm.options.height),
			maxWidth: _vm.maxWidth,
		},"image-restriction":"stencil"},on:{"ready":_vm.cropperReady,"change":_vm.change}})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }