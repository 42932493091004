<script>
export default {
	data() {
		return {
			width: 0,
			cropper: null,
		}
	},
	props: {
		config: Object,
		inscription: String,
		name: String,
		coverSrc: String,
	},
	computed: {
		imgAttrs() {
			const imgWidth = this.mmToPx(this.config.imgWidth)
			const imgHeight = this.mmToPx(this.config.imgHeight)
			return {
				width: Number(imgWidth),
				height: Number(imgHeight),
				aspectRatio: Number(imgWidth) / Number(imgHeight),
			}
		},
		scale() {
			const imgCoverWidth = this.mmToPx(this.config.imgCoverWidth)
			if (imgCoverWidth > this.width) {
				return this.width / imgCoverWidth
			}
			return 1
		},
		containerHeight() {
			return this.mmToPx(this.config.imgCoverHeight) * this.scale
		},
		backgroundImageUrl() {
			return this.$vuetify.breakpoint.xs
				? this.config.imgCover.small.fullUrl
				: this.config.imgCover.medium.fullUrl
		},
	},
	methods: {
		mmToPx(mm, dpi = 300) {
			return Number(((dpi / 25.4) * mm).toFixed(2))
		},
	},
	mounted() {
		this.width = this.$refs.container.offsetWidth
		window.addEventListener('resize', () => {
			this.width = this.$refs.container.offsetWidth
		})
	},
}
</script>

<template>
	<div
		class="w100 relative pb-16 mb-4 pb-md-0 mb-md-0"
		:style="{ height: `${containerHeight}px` }"
		ref="container"
	>
		<!-- <div class="text-center pb-4">
			Los botones + y - te permiten ampliar o reducir tu foto. Para posicionar tu foto, simplemente
			arrastras la imagen dentro del cuadro.
		</div> -->
		<div class="relative w100">
			<CropperControls
				v-if="cropper"
				:cropper="cropper"
				class="cropper-controls pt-4"
				:style="{ top: (mmToPx(config.imgTop) + mmToPx(config.imgHeight)) * scale + 'px' }"
			/>
			<div
				v-if="scale"
				:style="{
					width: `${mmToPx(config.imgCoverWidth)}px`,
					height: `${mmToPx(config.imgCoverHeight)}px`,
					transform: `scale(${scale})`,
					position: 'absolute',
					left: 0,
					top: 0,
					transformOrigin: '0 0',
					backgroundSize: 'cover',
					backgroundImage: `url('${backgroundImageUrl}')`,
				}"
			>
				<div
					:style="{
						fontSize: mmToPx(config.nameFontSize) + 'px',
						top: mmToPx(config.nameTop) + 'px',
						zIndex: 1000,
						width: '100%',
						position: 'absolute',
						fontFamily: 'Labor Union',
						textAlign: 'center',
						color: 'var(--v-secondary-base)',
					}"
					v-show="!!name"
				>
					{{ name }}
				</div>
				<div
					class="absolute"
					:style="{
						left: mmToPx(config.imgLeft) + 'px',
						top: mmToPx(config.imgTop) + 'px',
					}"
				>
					<v-img v-bind="imgAttrs" contain :src="coverSrc" />
				</div>
				<div
					:style="{
						fontSize: mmToPx(config.inscriptionFontSize) + 'px',
						top: mmToPx(config.inscriptionTop) + 'px',
						width: '100%',
						position: 'absolute',
						fontFamily: 'Labor Union',
						textAlign: 'center',
						color: 'white',
						zIndex: 1000,
					}"
				>
					{{ inscription }}
				</div>
			</div>
		</div>
	</div>
</template>

<style scoped>
.cropper-controls {
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	z-index: 100;
}
</style>
