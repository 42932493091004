<script>
export default {
	props: {
		textClass: {
			type: String,
			default: '',
		},
		iconColor: {
			type: String,
			default: 'secondary',
		},
	},
	methods: {
		showLoginDrawer() {
			this.$store.set('shop/loginDrawer', true)
		},
	},
	computed: {
		user() {
			return this.$store.get('shop/user')
		},
	},
}
</script>

<template>
	<div>
		<Button v-if="user" :to="{ name: 'user.account' }" text>
			<v-icon left :color="iconColor">mdi-account</v-icon>
			<span :class="textClass">{{ user.firstname }}</span>
		</Button>
		<Button v-else text @click="showLoginDrawer">
			<v-icon left :color="iconColor">mdi-account</v-icon>
			<span :class="textClass">Ingresar</span>
		</Button>
	</div>
</template>

<style></style>
